import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import * as langActions from 'store/ducks/langs/actions';
import * as authActions from 'store/ducks/auth/actions';
import { IResLang } from 'api/services/langs/types';
import translateRoles from 'helpers/translateRoles';
import Confirmation from 'shared/Confirmation/Confirmation';
import { SERVER_URL } from 'constants/project';
import Logo from 'assets/images/logo.svg';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import './Header.sass';

export const Header: React.FC = () => {
  const confirmationRef = useRef<any>();
  const dispatch = useDispatch();
  const { user } = useSelector((state: AppState) => state.userReducer);
  const { currentLang, langs, i18n } = useSelector(
    (state: AppState) => state.langReducer
  );
  const { main } = i18n;
  const [filteredLangs, setFilteredLangs] = useState<IResLang[]>([]);
  const [showLangs, setShowLangs] = useState(false);

  useEffect(() => {
    filterLangs();
  }, [currentLang?.id, langs]);

  const changeCurrentLang = (lang: IResLang) => {
    dispatch(langActions.changeLang(lang));
    setShowLangs(false);
    window?.location?.reload();
  };
  const signOut = () => {
    dispatch(authActions.signOut());
  };

  const filterLangs = () => {
    const filter = langs?.filter((i: IResLang) => i.id !== currentLang?.id);
    if (filter) setFilteredLangs(filter);
  };

  return (
    <>
      <Confirmation
        text={main.confirmLogout}
        title={main.logout}
        submit={signOut}
        ref={confirmationRef}
      />
      <div className='header' id='header'>
        <Link to='/' className='header__logo-link'>
          <img className='header__logo' src={Logo} alt='' />
        </Link>
        <div className='header__actions'>
          <div className='header__lang'>
            <div
              className={cn('header__lang-items', {
                'header__lang-items--active': showLangs,
              })}
            >
              <div
                className='header__lang-item'
                onClick={() => setShowLangs((prev) => !prev)}
              >
                {currentLang?.file?.src && (
                  <img
                    src={`${SERVER_URL}/${currentLang?.file?.src}`}
                    alt={currentLang?.title}
                  />
                )}
              </div>
              {!!filteredLangs.length && (
                <div className='header__lang-list'>
                  {filteredLangs.map((i: IResLang) => (
                    <div
                      key={i.id}
                      className='header__lang-list-item header__lang-item'
                      onClick={() => changeCurrentLang(i)}
                    >
                      <img
                        src={`${SERVER_URL}/${i?.file?.src}`}
                        alt={i.title}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className='header__role'>
            <div className='header__name'>{user?.name}</div>
            <div className='header__user-role'>
              {translateRoles(user?.role)}
            </div>
          </div>
          <div
            className='header__logout'
            onClick={() => confirmationRef.current.onShow()}
          >
            <ExitToAppIcon className='header__logout' />
          </div>
        </div>
      </div>
    </>
  );
};
