import { IResLang } from 'api/services/langs/types';
import { Reducer } from 'redux';
import { ILangInitState, LangTypes } from './types';
import data from './data';

const initState = {
  langs: undefined,
  currentLang: undefined,
  i18n: data.en,
};

const reducer: Reducer<ILangInitState> = (
  state = initState,
  { type, payload }
) => {
  switch (type) {
    case LangTypes.GET_LANGS:
      return {
        ...state,
        langs: payload.langs as IResLang[],
      };
    case LangTypes.CHANGE_LANG:
      return {
        ...state,
        currentLang: payload.lang as IResLang,
        i18n: data[payload.lang.code],
      };
    default:
      return state;
  }
};

export default reducer;
