import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Sidebar } from 'shared/Sidebar/Sidebar';
import { AppState } from 'store/store';
import { Header } from 'shared/Header/Header';
import { Footer } from 'shared/Footer/Footer';
import { Auth } from 'shared/Auth/Auth';
import appConfig from 'config/appConfig';
import cn from 'classnames';
import { useLocation } from 'react-router';
import Notification from 'shared/Notification/Notification';
import * as langActions from 'store/ducks/langs/actions';
import { getTaxonomies } from 'store/ducks/taxonomies/actions';

appConfig();

const App: React.FC = ({ children }) => {
  const { authorized } = useSelector((state: AppState) => state.authReducer);
  const [sidebarStatus, setSidebarStatus] = useState<boolean>(
    localStorage.getItem('showSidebar') === 'true'
  );

  const dispatch = useDispatch();

  const { langs, currentLang } = useSelector(
    (state: AppState) => state.langReducer
  );

  useEffect(() => {
    if (authorized) dispatch(getTaxonomies());
  }, []);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const { showNotification } = useSelector(
    (state: AppState) => state.notificationReducer
  );

  return (
    <>
      {!currentLang ? (
        <></>
      ) : !authorized ? (
        <Auth />
      ) : (
        <div className='wrapper'>
          <Header />
          <div className='main'>
            <Sidebar
              setSidebarStatus={setSidebarStatus}
              sidebarStatus={sidebarStatus}
            />
            <div
              className={cn('main__in', { 'main__in--full': sidebarStatus })}
            >
              {children}
            </div>
          </div>
          <Footer />
          {showNotification && <Notification />}
        </div>
      )}
    </>
  );
};

export default App;
