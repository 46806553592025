import React from 'react';
import { FileUploader } from 'shared/FileUploader/FileUploader';
import { IFormDefaultProps, IFormFile } from 'shared/Form/types';
import { FileTypes, IFile } from 'api/services/file/types';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import { SERVER_URL } from 'constants/project';
import './FormItemFile.sass';
import { areEqual } from 'helpers/areEqual';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { InsertDriveFile } from '@material-ui/icons';

interface Props {
	data: IFormFile;
	defaultProps: IFormDefaultProps;
}

const FormItemFileComp: React.FC<Props> = ({ data, defaultProps }) => {
	const { label, value, fileType, width, height } = data;
	const { onChange, name } = defaultProps;
	const { main } = useSelector((state: AppState) => state.langReducer.i18n);

	const selectFile = (file: IFile) => {
		onChange([{ name, value: file.src }]);
	};

	return (
		<div className='form-item-file'>
			{value && (
				<div
					className='form-item-file__block'
					onClick={() => onChange([{ name, value: '' }])}>
					<DeleteForeverIcon
						color='secondary'
						className='form-item-file__block-delete-icon'
					/>
					{fileType === 'IMAGE' && (
						<img
							src={`${SERVER_URL}/${value}`}
							className='form-item-file__image'
							alt=''
						/>
					)}
					{fileType === 'PDF' && (
						<PictureAsPdfIcon className='form-item-file__image' />
					)}

					{fileType === 'AUDIO' && (
						<AudiotrackIcon className='form-item-file__image' />
					)}

					{fileType === 'VIDEO' && (
						<OndemandVideoIcon className='form-item-file__image' />
					)}
					{fileType === 'FILE' && (
						<InsertDriveFile className='media__list-image' />
					)}
				</div>
			)}
			<FileUploader
				fileTypeImage={fileType === FileTypes.IMAGE}
				fileTypeFile={fileType === FileTypes.FILE}
				fileTypePDF={fileType === FileTypes.PDF}
				fileTypeAudio={fileType === FileTypes.AUDIO}
				fileTypeVideo={fileType === FileTypes.VIDEO}
				width={Number(width) || undefined}
				height={Number(height) || undefined}
				text={label || main.addFile}
				selectFile={selectFile}
			/>
		</div>
	);
};

export const FormItemFile = React.memo(FormItemFileComp, areEqual);
